<template>
  <nav
    class="flex flex-col justify-center fixed top-0 left-0 bottom-0 transition-all duration-300"
    :class="{
      'opacity-100': socials,
      'opacity-0': !socials,
    }"
  >
    <div
      class="transition-all duration-300 w-14 bg-slate-500 bg-opacity-70 shadow rounded-r"
    >
      <a
        v-for="social in socials"
        :key="social.id"
        :href="social.url"
        :title="social.name"
        target="_blank"
        class="group"
      >
        <NuxtImg
          :alt="social.name"
          height="56px"
          width="56px"
          class="px-3 max-w-none hover:w-20 transition-all duration-300 py-2 group-first:pt-4 group-last:pb-4"
          :src="social.icon.url"
        />
      </a>
    </div>
  </nav>
</template>

<script setup lang="ts">

const socials = [
  {
    url: "https://www.codecentric.de",
    name: "codecentric AG",
    icon: {
      url: "images/codecentric.png",
    },
  },
  {
    id: "107521506",
    name: "GitHub",
    updatedAt: "2022-02-12T15:55:07+01:00",
    url: "https://github.com/dominikfladung",
    icon: {
      url: "images/github.png",
    },
  },
  {
    id: "107521504",
    name: "LinkedIn",
    updatedAt: "2022-02-12T15:55:07+01:00",
    url: "https://www.linkedin.com/in/dominik-f-6072a8164/",
    icon: {
      url: "images/linkedin.png",
    },
  },
  {
    id: "107521503",
    name: "Xing",
    updatedAt: "2022-02-12T15:55:07+01:00",
    url: "https://www.xing.com/profile/Dominik_Fladung/cv",
    icon: {
      url: "images/xing.png",
    },
  },
  {
    id: "107521507",
    name: "Mail",
    updatedAt: "2022-02-12T15:55:07+01:00",
    url: "mailto:contact@teruslab.de",
    icon: {
      url: "images/mail.svg",
    },
  },
];
</script>

